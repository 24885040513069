import { graphql, useStaticQuery } from 'gatsby';

const useSiteData = () => {
  const data = useStaticQuery(
    graphql`
      query SITE_DATA_QUERY {
        navBarData: markdownRemark(frontmatter: { dataKey: { eq: "navbar" } }) {
          frontmatter {
            logo {
              linkType
              linkLabel
              linkURL
              icon
            }
            menuItems {
              linkLabel
              linkType
              linkURL
            }
          }
        }
        footerData: markdownRemark(frontmatter: { dataKey: { eq: "footer" } }) {
          frontmatter {
            title1
            title2
            title3
            description
            socialLinks {
              linkURL
              linkType
              linkLabel
              icon
            }
            secondaryLinks {
              linkURL
              linkType
              linkLabel
            }
            primaryLinks {
              linkURL
              linkType
              linkLabel
            }
            offices {
              name
              icon
            }
            contactLinks {
              description
              icon
              linkLabel
              linkType
              linkURL
            }
            footerCredit
          }
        }
      }
    `
  );
  return data;
};

export default useSiteData;

import { css } from 'styled-components';

// MEDIA QUERY MANAGER
/* 
[1200 - 1800] is where our normal styles apply
900 - 1200px:   Tablet landscape
600 - 900px:    Tablet portrait
0 - 600px:      Phone
1800px + :      Big desktop
 */

// 1em = 16px

const media = {
  tabLand: (...args) => css`
    @media only screen and (max-width: 75em) {
      ${css(...args)}
    }
  `,
  tabPort: (...args) => css`
    @media only screen and (max-width: 56.25em) {
      ${css(...args)}
    }
  `,
  phone: (...args) => css`
    @media only screen and (max-width: 37.5em) {
      ${css(...args)}
    }
  `,
  bigDesktop: (...args) => css`
    @media only screen and (min-width: 112.5em) {
      ${css(...args)}
    }
  `,
};

export default media;

import React from 'react';

const SvgIcon = ({ className, icon, label }) => {
  return (
    <svg className={className} aria-label={label}>
      <use href={`#${icon}`} />
    </svg>
  );
};

export default SvgIcon;

import React from 'react';
import SpriteSVG from '../../img/assets/sprite.svg';

const CustomSprite = () => {
  return (
    <div style={{ width: '0', height: '0', position: 'absolute' }}>
      <SpriteSVG />
    </div>
  );
};

export default CustomSprite;

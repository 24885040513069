import React from 'react';
import * as S from './navBar.styled';
import MobileMenu from './MobileMenu';
import MenuItems from './MenuItems';
import SvgIcon from '../../utils/SvgIcon';
import CustomLink from '../../utils/CustomLink';

export const NavbarElement = ({ data }) => {
  const { menuItems, logo } = data;
  const { linkURL, linkType, icon } = logo;

  return (
    <S.Navbar>
      <S.ContainerWrapper>
        <S.Logo>
          <CustomLink linkURL={linkURL} linkType={linkType}>
            <SvgIcon icon={icon} label={icon} />
          </CustomLink>
        </S.Logo>
        <S.DesktopMenu>
          <MenuItems menuItems={menuItems} />
        </S.DesktopMenu>
        <MobileMenu menuItems={menuItems} />
      </S.ContainerWrapper>
    </S.Navbar>
  );
};

const Navbar = (props) => {
  if (!props.data) {
    return null;
  }
  // const data = props.data.edges[0].node.frontmatter;
  return <NavbarElement data={props.data} />;
};

export default Navbar;

import React, { useState } from 'react';
import MenuItems from './MenuItems';
import * as S from './navBar.styled';

const MobileMenu = ({ menuItems }) => {
  const [open, setOpen] = useState(false);
  const closeMobileMenu = () => setOpen(false);

  return (
    <S.MobileMenu>
      <S.HamburgerIcon
        onClick={() => setOpen(!open)}
        className={open && 'opened'}
      >
        <span></span>
      </S.HamburgerIcon>
      {open && (
        <MenuItems
          menuItems={menuItems}
          isMobile={true}
          closeMobileMenu={closeMobileMenu}
        />
      )}
    </S.MobileMenu>
  );
};

export default MobileMenu;

import styled from 'styled-components';
import media from '../../layout/mediaQuery';
import Container from '../../layout/Container';

export const Footer = styled.div`
  background-color: var(--color-secondary);
  color: var(--color-grey-3);
  padding: 3rem 0 1.5rem 0;
  .offices {
    display: flex;
    flex-wrap: wrap;
    > * {
      margin-left: 1rem;
    }
  }
  a {
    &:hover {
      color: var(--color-primary);
    }
  }
`;

export const ContainerWrapper = styled(Container)`
  flex-direction: column;

  > * + * {
    margin-top: 1rem;
  }
`;

export const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  > * {
    width: calc(100% / 3 - 2rem);
    margin: 2rem 0;
  }

  > * + * {
    padding: 0 0 0 8rem;
  }

  ${media.tabLand`
    > * + * {
        padding: unset;
      }
    > * {
        width: calc(100% / 2 - 2rem);
      }
  `}
  ${media.tabPort`
    > * {
      
      width: 100%;
      max-width: 70rem;
      margin: 2rem auto;
    }
  `}
`;

export const Credit = styled.div`
  text-align: center;
`;

export const Abouts = styled.div`
  > * + * {
    margin-top: 2rem;
  }
`;
export const Links = styled.div`
  .icons {
    display: flex;
    > * + * {
      margin-left: 1rem;
    }
    .iconBox {
      width: 4rem;
      height: 4rem;
      border: 1px solid var(--color-primary);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 2rem;
        height: 2rem;
        fill: var(--color-primary);
      }

      &:hover {
        background-color: var(--color-primary);
        .icon {
          fill: var(--color-white);
        }
      }
    }
  }
`;
export const Locations = styled.div``;

export const Email = styled.div``;
export const Phone = styled.div`
  background-color: var(--color-primary);
`;
export const Address = styled.div``;

export const Heading = styled.h3`
  position: relative;
  margin-bottom: 3rem;
  &::after {
    content: '';
    background-color: var(--color-primary);
    display: block;
    height: 2px;
    position: absolute;
    left: 0;
    width: 18px;
    bottom: -10px;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const PrimaryLinks = styled.div`
  > *:not(:last-child) {
    ::after {
      content: '|';
      margin: 0 1rem;
    }
  }
`;

export const Copyright = styled.div`
  font-size: 1.3rem;
`;

import styled from 'styled-components';
import Container from '../../layout/Container';
import media from '../../layout/mediaQuery';

export const Navbar = styled.div`
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-1);
  text-transform: uppercase;
  font-weight: 700;
  transition: padding 200ms linear;
  position: relative;
  padding: 0.7 0;
  z-index: 100;
  font-size: 1.7rem;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const ContainerWrapper = styled(Container)``;

export const Logo = styled.div`
  margin-right: auto;
  a {
    svg {
      width: 20rem;
      height: 5rem;
    }
  }
`;

export const DesktopMenu = styled.div`
  ${media.tabPort`display: none;`}
`;
export const MobileMenu = styled.div`
  display: none;

  ${media.tabPort`display: flex;`}
`;

export const MenuItems = styled.ul`
  display: flex;
  > * + * {
    margin: 0 0 0 3rem;
  }

  ${media.tabPort`
    background-color: var(--color-secondary);
    > * + * {
        margin: 3rem 0 0 0;
    }
  `}

  ${media.tabPort`
    position: absolute;
    flex-direction: column;
    width: 100%;
    left: 0;
    transform: translateX(50%);
    margin: 5rem 0 0 -50%;
    padding: 6rem 0;
    text-align: center;`}
`;

export const MenuItem = styled.li`
  a {
    &.active {
      color: var(--color-primary);
    }
  }
  &:hover {
    color: var(--color-primary);
  }
  ${media.tabPort`
  color: var(--color-grey-3);
  `}
`;

export const HamburgerIcon = styled.div`
  width: 5rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 3rem;

  span {
    --display: block;
    --width: 100%;
    --height: 0.7rem;
    --background-color: var(--color-primary);
    --border-radius: 1.3rem;

    position: relative;
    display: var(--display);
    width: var(--width);
    height: var(--height);
    background-color: var(--background-color);
    border-radius: var(--border-radius);

    &::before,
    &::after {
      content: '';
      position: absolute;
      transition: transform 200ms linear;
      display: var(--display);
      width: var(--width);
      height: var(--height);
      background-color: var(--background-color);
      border-radius: var(--border-radius);
    }
    &::before {
      bottom: 1.5rem;
    }

    &::after {
      top: 1.5rem;
    }
  }
  &.opened {
    span {
      background-color: transparent;
      ::before {
        transform: rotate(-45deg);
        bottom: 0;
      }

      ::after {
        transform: rotate(45deg);
        top: 0;
      }
    }
  }
`;

import { createGlobalStyle } from 'styled-components';
import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import media from './mediaQuery';

const GlobalStyles = createGlobalStyle`

:root {
    --color-primary: #0066FF;
    --color-primary-light: #2287ff;
    --color-secondary: #333366;
    --color-secondary-light: #4c4c91;
    --color-accent: #BF1C33;
    --color-accent-light: #e0244c;
    --color-bg-light: #ecf3f9;
    --color-bg-dark: #d3e7f4;
    --color-white: #fff;
    --color-black: #000;
    --color-grey-1: #faf9f9;
    --color-grey-2: #f4f2f2;
    --color-grey-3: #f0eeee;
    --color-grey-4: #ccc;
    --color-grey-5: #999;
    --color-grey-6: #777;
    --color-grey-7: #333;

    --max-width: 117rem;
    --box-shadow-1: 0 0 10px 0 rgb(43 52 59 / 10%);
}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
    scroll-behavior: smooth;
    ${media.tabLand`font-size: 56.25%`}
    ${media.tabPort`font-size: 50%`}
    ${media.bigDesktop`font-size: 75%`}
}

body {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.7;
    font-size: 1.8rem;
    color: var(--color-grey-7);
    white-space: pre-line;

    a {
      text-decoration: none;
      color: inherit;
    }

    ul, li {
      list-style:none;
    }

    ::selection {
      background-color: var(--color-primary);
      color: #fff;
    }
}

.content-wrapper {
    max-width: 80rem;
    margin: 0 auto;
}

.u-max-width-70 {
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}
.fade-in.reveal {
  opacity: 1;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-40rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}
`;

export default GlobalStyles;

import React from 'react';

const containerStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  maxWidth: 'var(--max-width)',
  margin: '0 auto',
};

const Container = ({ className, children }) => {
  return (
    <div className={className} style={containerStyles}>
      {children}
    </div>
  );
};

export default Container;

import React from 'react';
import * as S from './navBar.styled';
import CustomLink from '../../utils/CustomLink';

const MenuItems = ({ closeMobileMenu, isMobile, menuItems }) => {
  return (
    <S.MenuItems>
      {menuItems.length > 0 &&
        menuItems.map((item) => {
          const { linkLabel, linkType, linkURL } = item;
          return (
            <S.MenuItem
              key={linkLabel}
              onClick={() => isMobile && closeMobileMenu()}
            >
              <CustomLink
                linkType={linkType}
                linkURL={linkURL}
                activeClassName="active"
              >
                {linkLabel}
              </CustomLink>
            </S.MenuItem>
          );
        })}
    </S.MenuItems>
  );
};

export default MenuItems;

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as S from './footer.styled';
import CustomLink from '../../utils/CustomLink';
import SvgIcon from '../../utils/SvgIcon';

const Footer = ({ data }) => {
  const {
    title1,
    title2,
    title3,
    description,
    socialLinks,
    secondaryLinks,
    primaryLinks,
    officesImage,
    offices,
    footerCredit,
  } = data;

  const image = getImage(officesImage);

  return (
    <S.Footer>
      <S.ContainerWrapper>
        <S.Contents>
          <S.Abouts>
            <S.Heading>{title1}</S.Heading>
            <p>{description}</p>
          </S.Abouts>
          <S.Links>
            <S.Heading>{title2}</S.Heading>
            <div className="icons">
              {socialLinks.length > 0 &&
                socialLinks.map((item) => {
                  const { linkLabel, linkType, linkURL, icon } = item;
                  return (
                    <CustomLink
                      linkType={linkType}
                      linkURL={linkURL}
                      target="_blank"
                    >
                      <div className="iconBox">
                        <SvgIcon
                          className="icon"
                          icon={icon}
                          label={linkLabel}
                        />
                      </div>
                    </CustomLink>
                  );
                })}
            </div>
          </S.Links>
          <S.Locations>
            <S.Heading>{title3}</S.Heading>
            <div className="offices">
              {offices.length > 0 &&
                offices.map((item) => {
                  const { name, icon } = item;
                  return (
                    <S.Location>
                      <SvgIcon icon={icon} label={icon} />
                      <p>{name}</p>
                    </S.Location>
                  );
                })}
            </div>
          </S.Locations>
        </S.Contents>
        <S.Credit>
          <S.PrimaryLinks>
            {primaryLinks.length > 0 &&
              primaryLinks.map((item) => {
                const { linkLabel, linkType, linkURL } = item;
                return (
                  <CustomLink linkType={linkType} linkURL={linkURL}>
                    {linkLabel}
                  </CustomLink>
                );
              })}
          </S.PrimaryLinks>
          <S.Copyright>{footerCredit}</S.Copyright>
        </S.Credit>
      </S.ContainerWrapper>
    </S.Footer>
  );
};

export default Footer;

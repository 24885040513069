import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import Footer from '../elements/Footer/Footer.component';
import useSiteMetadata from '../utils/useSiteMetadata';
import useSiteData from '../utils/useSiteData';
import CustomSprite from '../utils/CustomSprite';
import GlobalStyles from './GlobalStyles';
import Navbar from '../elements/Navbar/NavBar.component';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const { navBarData, footerData } = useSiteData();
  const navBar = navBarData.frontmatter;
  const footer = footerData.frontmatter;

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href={`${withPrefix('/')}img/afrik-us-favicon.svg`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/afrik-us-apple-touch-icon.png`}
        />
        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/afrik-us-favicon-black.svg`}
          color="#0066FF"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <GlobalStyles />
      <CustomSprite />
      <Navbar data={navBar} />
      <div>{children}</div>
      <Footer data={footer} />
    </div>
  );
};

export default TemplateWrapper;
